import React, { Component } from 'react'
import { css } from 'emotion'
import { COLOR, SCREEN } from 'f-web/src/styles'

const MATCHES = {
  // Mobile
  minXXS: window.matchMedia(`(min-width: ${SCREEN.XXS}px)`),
  minXS: window.matchMedia(`(min-width: ${SCREEN.XS}px)`),
  minSM: window.matchMedia(`(min-width: ${SCREEN.SM}px)`),
  maxXXS: window.matchMedia(`(max-width: ${SCREEN.XXS}px)`),
  maxXS: window.matchMedia(`(max-width: ${SCREEN.XS}px)`),
  maxSM: window.matchMedia(`(max-width: ${SCREEN.SM}px)`),

  // Tablet
  minMD: window.matchMedia(`(min-width: ${SCREEN.MD}px)`),
  maxMD: window.matchMedia(`(max-width: ${SCREEN.MD}px)`),

  // Laptop
  minLG: window.matchMedia(`(min-width: ${SCREEN.LG}px)`),
  minXL: window.matchMedia(`(min-width: ${SCREEN.XL}px)`),
  maxLG: window.matchMedia(`(max-width: ${SCREEN.LG}px)`),
  maxXL: window.matchMedia(`(max-width: ${SCREEN.XL}px)`),

  // 4k
  minXXL: window.matchMedia(`(min-width: ${SCREEN.XXL}px)`),
  maxXXL: window.matchMedia(`(max-width: ${SCREEN.XXL}px)`),
}

class FView extends Component {
  state = {}
  componentDidMount() {
    const { minXXSStyle, minXSStyle, minSMStyle, minMDStyle, minLGStyle, minXLStyle, minXXLStyle } = this.props
    const { minXXS, minXS, minSM, minMD, minLG, minXL, minXXL } = this.props
    if (minXXSStyle || minXXS) {
      this._onMatchMinXXS(MATCHES.minXXS)
      MATCHES.minXXS.addListener(this._onMatchMinXXS)
    }
    if (minXSStyle || minXS) {
      this._onMatchMinXS(MATCHES.minXS)
      MATCHES.minXS.addListener(this._onMatchMinXS)
    }
    if (minSMStyle || minSM) {
      this._onMatchMinSM(MATCHES.minSM)
      MATCHES.minSM.addListener(this._onMatchMinSM)
    }
    if (minMDStyle || minMD) {
      this._onMatchMinMD(MATCHES.minMD)
      MATCHES.minMD.addListener(this._onMatchMinMD)
    }
    if (minLGStyle || minLG) {
      this._onMatchMinLG(MATCHES.minLG)
      MATCHES.minLG.addListener(this._onMatchMinLG)
    }
    if (minXLStyle || minXL) {
      this._onMatchMinXL(MATCHES.minXL)
      MATCHES.minXL.addListener(this._onMatchMinXL)
    }
    if (minXXLStyle || minXXL) {
      this._onMatchMinXXL(MATCHES.minXXL)
      MATCHES.minXXL.addListener(this._onMatchMinXXL)
    }
    const { maxXXSStyle, maxXSStyle, maxSMStyle, maxMDStyle, maxLGStyle, maxXLStyle, maxXXLStyle } = this.props
    const { maxXXS, maxXS, maxSM, maxMD, maxLG, maxXL, maxXXL } = this.props
    if (maxXXSStyle || maxXXS) {
      this._onMatchMaxXXS(MATCHES.maxXXS)
      MATCHES.maxXXS.addListener(this._onMatchMaxXXS)
    }
    if (maxXSStyle || maxXS) {
      this._onMatchMaxXS(MATCHES.maxXS)
      MATCHES.maxXS.addListener(this._onMatchMaxXS)
    }
    if (maxSMStyle || maxSM) {
      this._onMatchMaxSM(MATCHES.maxSM)
      MATCHES.maxSM.addListener(this._onMatchMaxSM)
    }
    if (maxMDStyle || maxMD) {
      this._onMatchMaxMD(MATCHES.maxMD)
      MATCHES.maxMD.addListener(this._onMatchMaxMD)
    }
    if (maxLGStyle || maxLG) {
      this._onMatchMaxLG(MATCHES.maxLG)
      MATCHES.maxLG.addListener(this._onMatchMaxLG)
    }
    if (maxXLStyle || maxXL) {
      this._onMatchMaxXL(MATCHES.maxXL)
      MATCHES.maxXL.addListener(this._onMatchMaxXL)
    }
    if (maxXXLStyle || maxXXL) {
      this._onMatchMaxXXL(MATCHES.maxXXL)
      MATCHES.maxXXL.addListener(this._onMatchMaxXXL)
    }
  }
  componentWillUnmount() {
    MATCHES.minXXS.removeListener(this._onMatchMinXXS)
    MATCHES.minXS.removeListener(this._onMatchMinXS)
    MATCHES.minSM.removeListener(this._onMatchMinSM)
    MATCHES.minMD.removeListener(this._onMatchMinMD)
    MATCHES.minLG.removeListener(this._onMatchMinLG)
    MATCHES.minXL.removeListener(this._onMatchMinXL)
    MATCHES.minXXL.removeListener(this._onMatchMinXXL)

    MATCHES.maxXXS.removeListener(this._onMatchMaxXXS)
    MATCHES.maxXS.removeListener(this._onMatchMaxXS)
    MATCHES.maxSM.removeListener(this._onMatchMaxSM)
    MATCHES.maxMD.removeListener(this._onMatchMaxMD)
    MATCHES.maxLG.removeListener(this._onMatchMaxLG)
    MATCHES.maxXL.removeListener(this._onMatchMaxXL)
    MATCHES.maxXXL.removeListener(this._onMatchMaxXXL)
  }
  render() {
    const {
      children,
      forwardedRef,
      style: customStyle,
      className: customClassName,
      minXXS,
      minXS,
      minSM,
      minMD,
      minLG,
      minXL,
      minXXL,
      minXXSStyle,
      minXSStyle,
      minSMStyle,
      minMDStyle,
      minLGStyle,
      minXLStyle,
      minXXLStyle,
      maxXXS,
      maxXS,
      maxSM,
      maxMD,
      maxLG,
      maxXL,
      maxXXL,
      maxXXSStyle,
      maxXSStyle,
      maxSMStyle,
      maxMDStyle,
      maxLGStyle,
      maxXLStyle,
      maxXXLStyle,
      onClick,
      'data-aos': dataAOS,
      tabIndex,
      ...props
    } = this.props
    // 1. Apply base style
    const style = [styles.baseStyle]

    // 2. Apply customStyle passed down as this.props.style
    style.push(customStyle)
    style.push(customClassName)

    // 3. Apply prop styles
    const keys = Object.keys(props)
    for (const key of keys) {
      if (props[key] === true && styles[key]) {
        style.push(styles[key])
      }
      // Check if key is any of the following
      // ph - padding horizontal
      // pv - padding vertical
      // pl - padding left
      // pr - padding right
      // pt - padding top
      // pb - padding bottom
      // p - padding
      // same for margin but starts with m instead of p
      // w - width
      // h - height
      // bg - backgroundColor
      // br - borderRadius
      // if so then the value is the corresponding style value
      // ex. ph={20} -> style={{paddingHorizontal: 20}}
      // ex. w='100%' -> style={{width: '100%'}}

      switch (key) {
        case 'mh':
          style.push(css({ marginLeft: props[key], marginRight: props[key] }))
          break
        case 'mv':
          style.push(css({ marginTop: props[key], marginBottom: props[key] }))
          break
        case 'mt':
          style.push(css({ marginTop: props[key] }))
          break
        case 'mb':
          style.push(css({ marginBottom: props[key] }))
          break
        case 'ml':
          style.push(css({ marginLeft: props[key] }))
          break
        case 'mr':
          style.push(css({ marginRight: props[key] }))
          break
        case 'm':
          style.push(css({ margin: props[key] }))
          break
        case 'ph':
          style.push(css({ paddingLeft: props[key], paddingRight: props[key] }))
          break
        case 'pv':
          style.push(css({ paddingTop: props[key], paddingBottom: props[key] }))
          break
        case 'pt':
          style.push(css({ paddingTop: props[key] }))
          break
        case 'pb':
          style.push(css({ paddingBottom: props[key] }))
          break
        case 'pl':
          style.push(css({ paddingLeft: props[key] }))
          break
        case 'pr':
          style.push(css({ paddingRight: props[key] }))
          break
        case 'p':
          style.push(css({ padding: props[key] }))
          break
        case 'w':
          style.push(css({ width: props[key] }))
          break
        case 'h':
          style.push(css({ height: props[key] }))
          break
        case 'size':
          style.push(css({ width: props[key], height: props[key] }))
          break
        case 'maxSize':
          style.push(css({ maxWidth: props[key], maxHeight: props[key] }))
          break
        case 'bg':
          style.push(css({ backgroundColor: props[key] }))
          break
        case 'br':
          style.push(css({ borderRadius: props[key] }))
          break
        case 'blr':
          style.push(css({ borderTopLeftRadius: props[key], borderBottomLeftRadius: props[key] }))
          break
        case 'brr':
          style.push(css({ borderTopRightRadius: props[key], borderBottomRightRadius: props[key] }))
          break
        case 'btr':
          style.push(css({ borderTopLeftRadius: props[key], borderTopRightRadius: props[key] }))
          break
        case 'bbr':
          style.push(css({ borderBottomLeftRadius: props[key], borderBottomRightRadius: props[key] }))
          break
        case 'bc':
          style.push(css({ border: 'solid', borderColor: props[key] }))
          break
        case 'bw':
          style.push(css({ borderWidth: props[key] }))
          break
        case 'bbw':
          style.push(css({ borderBottomWidth: props[key] }))
          break
        case 'btw':
          style.push(css({ borderTopWidth: props[key] }))
          break
        case 'blw':
          style.push(css({ borderLeftWidth: props[key] }))
          break
        case 'brw':
          style.push(css({ borderRightWidth: props[key] }))
          break
        case 'color':
        case 'fontSize':
        case 'whiteSpace':
        case 'lineHeight':
        case 'textDecoration':
          console.warn(key + ' deprecated. Use FText instead')
          style.push(css({ [key]: props[key] }))
          break
        case 'cursor':
        case 'top':
        case 'bottom':
        case 'left':
        case 'right':
        case 'maxWidth':
        case 'minWidth':
        case 'minHeight':
        case 'maxHeight':
        case 'backgroundImage':
        case 'backgroundSize':
        case 'zIndex':
        case 'overflow':
        case 'overflowX':
        case 'overflowY':
        case 'flexDirection':
        case 'pointerEvents':
        case 'opacity':
        case 'transition':
        case 'transform':
        case 'flexWrap':
        case 'outline':
          style.push(css({ [key]: props[key] }))
          break
        case 'varelaRound':
        case 'muliLight':
        case 'muliBlack':
        case 'heading':
        case 'heading2':
        case 'content':
        case 'dafoe':
        case 'din':
        case 'tech':
        case 'black':
        case 'red':
        case 'grey':
        case 'white':
        case 'gold':
        case 'textDisabled':
        case 'textLeft':
        case 'textCenter':
        case 'textRight':
        case 'superhero':
        case 'hero':
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
        case 'h6':
        case 'h7':
        case 'xBold':
        case 'bold':
        case 'thin':
        case 'xThin':
          console.warn(key + ' deprecated. Use FText instead')
          break
        case 'fill':
          if (props[key] !== true) {
            style.push(css({ flex: props[key] }))
          }
          break
        default:
          break
      }
    }

    // 4. Apply mediaquery style passed down as minXXSStyle, minXSStyle, minSMStyle, minMDStyle, minLGStyle, minXLStyle, minXXLStyle
    const { matchMinXXS, matchMinXS, matchMinSM, matchMinMD, matchMinLG, matchMinXL, matchMinXXL } = this.state
    if (matchMinXXS) {
      style.push(minXXSStyle)
    } else if (minXXS) {
      style.push(styles.none)
    }
    if (matchMinXS) {
      style.push(minXSStyle)
    } else if (minXS) {
      style.push(styles.none)
    }
    if (matchMinSM) {
      style.push(minSMStyle)
    } else if (minSM) {
      style.push(styles.none)
    }
    if (matchMinMD) {
      style.push(minMDStyle)
    } else if (minMD) {
      style.push(styles.none)
    }
    if (matchMinLG) {
      style.push(minLGStyle)
    } else if (minLG) {
      style.push(styles.none)
    }
    if (matchMinXL) {
      style.push(minXLStyle)
    } else if (minXL) {
      style.push(styles.none)
    }
    if (matchMinXXL) {
      style.push(minXXLStyle)
    } else if (minXXL) {
      style.push(styles.none)
    }

    // 5. Apply mediaquery style passed down as maxXXSStyle, maxXSStyle, maxSMStyle, maxMDStyle, maxLGStyle, maxXLStyle, maxXXLStyle
    const { matchMaxXXS, matchMaxXS, matchMaxSM, matchMaxMD, matchMaxLG, matchMaxXL, matchMaxXXL } = this.state
    if (matchMaxXXS) {
      style.push(maxXXSStyle)
    } else if (maxXXS) {
      style.push(styles.none)
    }
    if (matchMaxXS) {
      style.push(maxXSStyle)
    } else if (maxXS) {
      style.push(styles.none)
    }
    if (matchMaxSM) {
      style.push(maxSMStyle)
    } else if (maxSM) {
      style.push(styles.none)
    }
    if (matchMaxMD) {
      style.push(maxMDStyle)
    } else if (maxMD) {
      style.push(styles.none)
    }
    if (matchMaxLG) {
      style.push(maxLGStyle)
    } else if (maxLG) {
      style.push(styles.none)
    }
    if (matchMaxXL) {
      style.push(maxXLStyle)
    } else if (maxXL) {
      style.push(styles.none)
    }
    if (matchMaxXXL) {
      style.push(maxXXLStyle)
    } else if (maxXXL) {
      style.push(styles.none)
    }

    return (
      <div ref={forwardedRef} className={css(style)} onClick={onClick} data-aos={dataAOS} tabIndex={tabIndex}>
        {children}
      </div>
    )
  }
  _onMatchMinXXS = ({ matches }) => {
    this.setState({ matchMinXXS: matches })
  }
  _onMatchMinXS = ({ matches }) => {
    this.setState({ matchMinXS: matches })
  }
  _onMatchMinSM = ({ matches }) => {
    this.setState({ matchMinSM: matches })
  }
  _onMatchMinMD = ({ matches }) => {
    this.setState({ matchMinMD: matches })
  }
  _onMatchMinLG = ({ matches }) => {
    this.setState({ matchMinLG: matches })
  }
  _onMatchMinXL = ({ matches }) => {
    this.setState({ matchMinXL: matches })
  }
  _onMatchMinXXL = ({ matches }) => {
    this.setState({ matchMinXXL: matches })
  }

  _onMatchMaxXXS = ({ matches }) => {
    this.setState({ matchMaxXXS: matches })
  }
  _onMatchMaxXS = ({ matches }) => {
    this.setState({ matchMaxXS: matches })
  }
  _onMatchMaxSM = ({ matches }) => {
    this.setState({ matchMaxSM: matches })
  }
  _onMatchMaxMD = ({ matches }) => {
    this.setState({ matchMaxMD: matches })
  }
  _onMatchMaxLG = ({ matches }) => {
    this.setState({ matchMaxLG: matches })
  }
  _onMatchMaxXL = ({ matches }) => {
    this.setState({ matchMaxXL: matches })
  }
  _onMatchMaxXXL = ({ matches }) => {
    this.setState({ matchMaxXXL: matches })
  }
}

const styles = {
  baseStyle: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  shadow: css({
    boxShadow: '0 8px 32px rgba(7, 18, 37, 0.2)',
  }),
  shadow75: css({
    boxShadow: '0 6px 24px rgba(7, 18, 37, 0.2)',
  }),
  shadow50: css({
    boxShadow: '0 4px 16px rgba(7, 18, 37, 0.2)',
  }),
  shadow25: css({
    boxShadow: '0 2px 8px rgba(7, 18, 37, 0.2)',
  }),
  shadow10: css({
    boxShadow: '0 1px 4px rgba(7, 18, 37, 0.2)',
  }),
  shadowHover: css({
    boxShadow: '0 2px 8px rgba(7, 18, 37, 0.2)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    ':hover': {
      transform: 'translateX(2px)',
      boxShadow: '0 6px 24px rgba(7, 18, 37, 0.2)',
    },
  }),
  rounded: css({ borderRadius: 4 }),
  varelaRound: css({
    fontFamily: 'Varela Round',
  }),
  muliLight: css({
    fontFamily: 'Muli',
    fontWeight: '300',
  }),
  muliBlack: css({
    fontFamily: 'Muli',
    fontWeight: '900',
  }),
  flex: css({
    display: 'flex',
  }),
  none: css({
    display: 'none',
  }),
  block: css({
    display: 'block',
  }),
  inline: css({
    display: 'inline',
  }),
  inlineBlock: css({
    display: 'inline-block',
  }),
  row: css({
    flexDirection: 'row',
  }),
  rowReverse: css({
    flexDirection: 'row-reverse',
  }),
  column: css({
    flexDirection: 'column',
  }),
  columnReverse: css({
    flexDirection: 'column-reverse',
  }),
  fill: css({
    flex: 1,
  }),
  flexShrink: css({
    flexShrink: 1,
  }),
  flexGrow: css({
    flexGrow: 1,
  }),
  wrap: css({
    flexWrap: 'wrap',
  }),
  absoluteFill: css({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  }),
  relative: css({
    position: 'relative',
  }),
  absolute: css({
    position: 'absolute',
  }),
  fixed: css({
    position: 'fixed',
  }),
  selfCenter: css({
    alignSelf: 'center',
  }),
  selfStart: css({
    alignSelf: 'flex-start',
  }),
  selfEnd: css({
    alignSelf: 'flex-end',
  }),
  selfStretch: css({
    alignSelf: 'stretch',
  }),
  center: css({
    alignItems: 'center',
    justifyContent: 'center',
  }),
  alignCenter: css({
    alignItems: 'center',
  }),
  alignStart: css({
    alignItems: 'flex-start',
  }),
  alignEnd: css({
    alignItems: 'flex-end',
  }),
  alignStretch: css({
    alignItems: 'stretch',
  }),
  justifyCenter: css({
    justifyContent: 'center',
  }),
  justifyBetween: css({
    justifyContent: 'space-between',
  }),
  justifyAround: css({
    justifyContent: 'space-around',
  }),
  justifyEvenly: css({
    justifyContent: 'space-evenly',
  }),
  justifyEnd: css({
    justifyContent: 'flex-end',
  }),
  pointer: css({
    cursor: 'pointer',
  }),
  heading: css({
    fontFamily: 'futura',
  }),
  heading2: css({
    fontFamily: 'antonio',
  }),
  content: css({
    fontFamily: 'roboto',
  }),
  dafoe: css({
    fontFamily: 'dafoe',
  }),
  din: css({
    fontFamily: 'din',
  }),
  tech: css({
    fontFamily: 'tech',
  }),
  black: css({
    color: COLOR.black,
  }),
  red: css({
    color: COLOR.red,
  }),
  grey: css({
    color: COLOR.darkGrey,
  }),
  white: css({
    color: COLOR.white,
  }),
  gold: css({
    color: COLOR.Gold,
  }),
  textDisabled: css({
    color: 'rgba(255,255,255,0.6)',
  }),
  textLeft: css({
    textAlign: 'left',
  }),
  textCenter: css({
    textAlign: 'center',
  }),
  textRight: css({
    textAlign: 'right',
  }),
  superhero: css({
    fontSize: 50,
  }),
  hero: css({
    fontSize: 40,
  }),
  h1: css({
    fontSize: 36,
  }),
  h2: css({
    fontSize: 24,
  }),
  h3: css({
    fontSize: 20,
  }),
  h4: css({
    fontSize: 18,
  }),
  h5: css({
    fontSize: 16,
  }),
  h6: css({
    fontSize: 14,
  }),
  h7: css({
    fontSize: 12,
  }),
  xBold: css({
    fontWeight: '900',
  }),
  bold: css({
    fontWeight: '700',
  }),
  thin: css({
    fontWeight: '300',
  }),
  xThin: css({
    fontWeight: '100',
  }),
}

export default React.forwardRef((props, ref) => <FView forwardedRef={ref} {...props} />)
