import React from 'react'
import { FText, FView, NavBar } from 'components'
import { useMediaMinLG } from 'f-web/src/hooks'
import { colors } from 'styles'
import * as images from 'images'
import Hero from './Hero'
import Section from './Section'
import BusinessInfo from './BusinessInfo'
import SectionLabel from './SectionLabel'

const FeaturedItem = ({ imgSrc, label, description }) => {
  return (
    <FView selfCenter w={768} maxWidth="100%">
      <img src={imgSrc} alt={''} style={{ objectFit: 'contain', width: '100%', height: 'auto' }} />
      <FView size={15} />
      <FText h5 bold textCenter>
        {label}
      </FText>
      <FView size={10} />
      <FText h6 textCenter>
        {description}
      </FText>
    </FView>
  )
}

export default function Home() {
  const isMinLG = useMediaMinLG()

  return (
    <FView minHeight={'100vh'} bg={colors.background} overflowX={'hidden'} overflowY="auto">
      <NavBar />
      <Hero />
      <FView size={100} />
      <Section>
        <SectionLabel>Featured Items</SectionLabel>
        <FView size={50} />
        {isMinLG ? (
          <>
            <FView relative h={400} row alignCenter>
              <FView w={500} bg={colors.black50} zIndex={2} ph={50} pv={25}>
                <FText h4 bold textCenter>
                  Hot Pot
                </FText>
                <FView size={15} />
                <FText h5 textCenter>
                  A Korean cuisine classic. We offer a variety of hot pots including pork bone soup, beef bulgogi, and
                  more!
                </FText>
              </FView>
              <FView absolute right={0}>
                <img src={images.feature1} alt="" />
              </FView>
            </FView>
            <FView relative h={400} row alignCenter justifyEnd mt={-25}>
              <FView absolute left={0} zIndex={1}>
                <img src={images.feature2} alt="" />
              </FView>
              <FView w={500} bg={colors.black50} zIndex={2} ph={50} pv={25}>
                <FText h4 bold textCenter>
                  Korean Fried Chicken
                </FText>
                <FView size={15} />
                <FText h5 textCenter>
                  Delicious Korean fried chicken. We offer many flavours that will certainly suit your tastes!
                </FText>
              </FView>
            </FView>
            <FView relative h={400} row alignCenter mt={-25}>
              <FView w={500} bg={colors.black50} zIndex={2} ph={50} pv={25}>
                <FText h4 bold textCenter>
                  Donkatsu
                </FText>
                <FView size={15} />
                <FText h5 textCenter>
                  Our famous homemade crispy deep fried cutlet served with salad and rice on the side.
                </FText>
              </FView>
              <FView absolute right={0}>
                <img src={images.feature3} alt="" />
              </FView>
            </FView>
          </>
        ) : (
          <>
            <FeaturedItem
              imgSrc={images.feature1}
              label={'Hot Pot'}
              description={
                'A Korean cuisine classic. We offer a variety of hot pots including pork bone soup, beef bulgogi, and more!'
              }
            />
            <FView size={25} />
            <FeaturedItem
              imgSrc={images.feature2}
              label={'Korean Fried Chicken'}
              description={
                'Delicious Korean fried chicken. We offer many flavours that will certainly suit your tastes!'
              }
            />
            <FView size={25} />
            <FeaturedItem
              imgSrc={images.feature3}
              label={'Donkatsu'}
              description={' Our famous homemade crispy deep fried cutlet served with salad and rice on the side.'}
            />
          </>
        )}
      </Section>
      <FView size={isMinLG ? 100 : 50} />

      <Section>
        <SectionLabel>Now Taking Online Orders</SectionLabel>
        <FView size={isMinLG ? 50 : 15} />
        <FText h5 textCenter>
          Oz Chicken is now partnering with Foodly to serve you better! You can order online from our website or by
          downloading their app in your app store.
        </FText>
        <FView size={isMinLG ? 50 : 15} />
        <FView justifyBetween={isMinLG} alignCenter>
          <FView w={1024} maxWidth="100%">
            <img
              src={images.mobilePromo}
              alt="now taking online orders"
              style={{ objectFit: 'contain', maxWidth: '100%', height: 'auto' }}
            />
          </FView>
          {!isMinLG && <FView size={15} />}
          <FView w={400} maxWidth={'100%'}></FView>
        </FView>
      </Section>
      <FView size={isMinLG ? 100 : 50} />
      <Section>
        <SectionLabel>About Us</SectionLabel>
        <FView size={isMinLG ? 50 : 15} />
        <FView row={isMinLG} justifyBetween={isMinLG} alignCenter>
          <img
            src={images.aboutUsPhoto}
            alt="about us"
            style={{ objectFit: 'contain', maxWidth: '100%', height: 'auto' }}
          />
          {!isMinLG && <FView size={25} />}
          <FView w={390} maxWidth={'100%'}>
            <FText h5 textCenter>
              Korean cuisine is well known for its balance and finesse. We proudly serve authentic Korean dishes made
              with fresh, high quality ingredients. Come visit us at our location in Coquitlam to try one of our dishes!
            </FText>
          </FView>
        </FView>
      </Section>
      <FView size={isMinLG ? 100 : 50} />
      <BusinessInfo />
    </FView>
  )
}
