export { default as foodlyFooterLogo } from './foodlyFooterLogo.svg'
export { default as icon } from './icon.svg'

export const heroBackground =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto/ozkoreancuisine/website/heroBackground.png'
export const heroImg1 = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto/ozkoreancuisine/website/heroImg1.png'
export const heroImg2 = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto/ozkoreancuisine/website/heroImg2.png'
export const heroImg3 = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto/ozkoreancuisine/website/heroImg3.png'
export const feature1 = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto/ozkoreancuisine/website/feature1.png'
export const feature2 = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto/ozkoreancuisine/website/feature2.png'
export const feature3 = 'https://res.cloudinary.com/foodlytech/image/upload/f_auto/ozkoreancuisine/website/feature3.png'
export const mobilePromo =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto/ozkoreancuisine/website/mobilePromo.png'
export const aboutUsPhoto =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto/ozkoreancuisine/website/aboutUsPhoto.png'
export const locationMap =
  'https://res.cloudinary.com/foodlytech/image/upload/f_auto/ozkoreancuisine/website/locationMap.png'
