import React from 'react'
import { FText, FView, ButtonFillView, FButton } from 'components'
import { useMediaMinMD, useMediaMinLG } from 'f-web/src/hooks'
import * as images from 'images'

export default function Hero() {
  const isMinMD = useMediaMinMD()
  const isMinLG = useMediaMinLG()
  return (
    <FView minHeight={250} h={'66.7vw'} maxHeight={'100vh'} relative>
      <img
        src={images.heroBackground}
        style={{ objectFit: 'cover', width: 'auto', height: '100%' }}
        alt="hero background"
      />

      <FView absolute left={0} top={0} h={'62.5vw'} maxHeight={'calc(100vh - 60px)'}>
        <img
          src={images.heroImg1}
          style={{ objectFit: 'contain', width: 'auto', height: '100%' }}
          alt={'hero fried chicken'}
        />
      </FView>
      <FView absolute right={0} top={0} bottom={0} justifyCenter>
        <FView h={'55.5vw'} maxHeight={'83vh'}>
          <img
            src={images.heroImg2}
            style={{ objectFit: 'contain', width: 'auto', height: '100%' }}
            alt={'hero kimchi fried rice'}
          />
        </FView>
      </FView>
      <FView absolute right={'23.7vw'} top={0} h={'18.1vw'} maxHeight={'27.1vh'}>
        <img src={images.heroImg3} style={{ objectFit: 'contain', width: 'auto', height: '100%' }} alt={'hero sauce'} />
      </FView>
      <FView absoluteFill center>
        <FView>
          <FText h7 h6={isMinMD} h4={isMinLG} white bold>
            Welcome To
          </FText>
          <FText h4 h3={isMinMD} h1={isMinLG} white bold>
            Oz Chicken
          </FText>
        </FView>
        <FView size={25} />
        <FButton animateOnHover onClick={() => window.location.assign('https://foodly.ca/links/ozchicken')}>
          <ButtonFillView w={250} rounded>
            <FText h7 bold white>
              Order Now
            </FText>
          </ButtonFillView>
        </FButton>
      </FView>
    </FView>
  )
}
