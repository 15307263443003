import React, { Component } from 'react'
import { css } from 'emotion'

class FButton extends Component {
  render() {
    const { className, children, disabled, ...props } = this.props
    const classNames = css(classes.button, className)
    return (
      <button className={classNames} disabled={disabled} {...props}>
        {children}
      </button>
    )
  }
}

const classes = {
  button: css({
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    margin: 0,
    padding: 0,
    width: 'auto',
    overflow: 'visible',
    background: 'transparent',
    color: 'inherit',
    font: 'inherit',
    lineHeight: 'normal',
    textAlign: 'inherit',
    '-webkitTapHighlightColor': 'rgba(0,0,0,0)',
  }),
}

export default FButton
