import React from 'react'
import { FView } from 'components'
import { useMediaMinLG } from 'f-web/src/hooks'

export default function Section({ children, ...props }) {
  const isMinLG = useMediaMinLG()
  return (
    <FView selfCenter w={1024} maxWidth={'100vw'} {...props} ph={!isMinLG && 15}>
      {children}
    </FView>
  )
}
