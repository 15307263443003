import React from 'react'
import { FText, FView, FButton, ButtonFillView } from 'components'
import { useMediaMinMD } from 'f-web/src/hooks'
import { colors } from 'styles'
import * as images from 'images'
import useReactRouter from 'use-react-router'

export default function NavBar() {
  const { history } = useReactRouter()
  const isMinMD = useMediaMinMD()
  return (
    <FView h={!isMinMD && 80}>
      <FView fixed top={0} left={0} right={0} bg={colors.black50} h={isMinMD ? 100 : 80} zIndex={10} justifyCenter>
        <FView selfCenter row justifyBetween w={1024} maxWidth={'90vw'}>
          <FButton animateOnHover row onClick={() => history.push('/')}>
            <img src={images.icon} alt={'oz chicken icon'} style={{ height: 45 }} />
            <FView size={10} />
            <FText h6 bold white>
              Chicken
            </FText>
          </FButton>
          <FView row alignCenter>
            <FButton animateOnHover onClick={() => window.location.assign('https://foodly.ca/links/ozchicken')}>
              <ButtonFillView rounded w={165}>
                <FText h7 bold white>
                  Order Now
                </FText>
              </ButtonFillView>
            </FButton>
          </FView>
        </FView>
      </FView>
    </FView>
  )
}
