import React from 'react'
import { FText, FView, FButton, ButtonFillView } from 'components'
import { colors } from 'styles'
import * as images from 'images'
import { useMediaMinLG } from 'f-web/src/hooks'
import Section from './Section'

export default function BusinessInfo() {
  const isMinLG = useMediaMinLG()
  return (
    <>
      <FView h={1} bg={colors.lightGrey} />
      <FView size={isMinLG ? 50 : 15} />
      <Section>
        <FView row={isMinLG} justifyBetween={isMinLG} alignCenter={!isMinLG}>
          <a href={'https://g.page/oz-chicken'}>
            <img src={images.locationMap} alt="location map" />
          </a>
          {!isMinLG && <FView size={25} />}
          <FView w={300} justifyBetween minHeight={265} alignCenter={!isMinLG}>
            <FView>
              <FText h5 bold color={colors.primary} roboto textCenter={!isMinLG}>
                CONTACT US
              </FText>
              <FView size={20} />
              <FView row>
                <FView w={75}>
                  <FText h8 bold roboto>
                    Address
                  </FText>
                </FView>
                <address>
                  <FText h8 bold roboto>
                    #112-1190 Pinetree Way, Coquitlam
                  </FText>
                </address>
              </FView>
              <FView size={15} />

              <FView row>
                <FView w={75}>
                  <FText h8 bold roboto>
                    Phone
                  </FText>
                </FView>
                <a href="tel:7789418633">
                  <FText h8 bold roboto>
                    (778) 941-8633
                  </FText>
                </a>
              </FView>
              <FView size={15} />

              <FView row>
                <FView w={75}>
                  <FText h8 bold roboto>
                    Email
                  </FText>
                </FView>
                <a href="mailto:ozchicken@foodly.ca">
                  <FText h8 bold roboto>
                    ozchicken@foodly.ca
                  </FText>
                </a>
              </FView>
            </FView>
            <FView alignCenter={!isMinLG}>
              <FText h5 bold color={colors.primary} roboto>
                ORDER ONLINE
              </FText>
              <FView size={20} />
              <FButton
                selfStart
                animateOnHover
                onClick={() => window.location.assign('https://foodly.ca/links/ozchicken')}
              >
                <ButtonFillView w={250} rounded>
                  <FText h7 bold white>
                    Order Now
                  </FText>
                </ButtonFillView>
              </FButton>
            </FView>
          </FView>
          {!isMinLG && <FView size={25} />}
          <FView w={215} minHeight={265}>
            <FView fill>
              <FText h5 bold color={colors.primary} roboto textCenter={!isMinLG}>
                BUSINESS HOURS
              </FText>
              <FView size={20} />
              <FView justifyBetween fill>
                <HoursItem dayLabel={'Mon'} hourLabel={'11:30 AM - 12:00 AM'} />
                <HoursItem dayLabel={'Tue'} hourLabel={'11:30 AM - 12:00 AM'} />
                <HoursItem dayLabel={'Wed'} hourLabel={'11:30 AM - 12:00 AM'} />
                <HoursItem dayLabel={'Thu'} hourLabel={'11:30 AM - 12:00 AM'} />
                <HoursItem dayLabel={'Fri'} hourLabel={'11:30 AM - 12:00 AM'} />
                <HoursItem dayLabel={'Sat'} hourLabel={'11:30 AM - 12:00 AM'} />
                <HoursItem dayLabel={'Sun'} hourLabel={'11:30 AM - 12:00 AM'} />
              </FView>
            </FView>
          </FView>
        </FView>
      </Section>
      <FView size={75} />
      <FView selfCenter alignCenter>
        <FText roboto h9 textCenter>
          COPYRIGHT © 2019 FOODLY TECHNOLOGIES INC. ALL RIGHTS RESERVED
        </FText>
        <FButton animateOnHover onClick={() => window.location.assign('https://foodly.ca')}>
          <img src={images.foodlyFooterLogo} alt="foodly footer logo" />
        </FButton>
        <FView size={15} />
      </FView>
    </>
  )
}

const HoursItem = ({ dayLabel, hourLabel }) => {
  return (
    <FView row justifyBetween>
      <FText h8 bold roboto>
        {dayLabel}
      </FText>
      <FText h8 bold roboto>
        {hourLabel}
      </FText>
    </FView>
  )
}
