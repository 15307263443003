import React from 'react'
import { FText, FView } from 'components'
import { colors } from 'styles'
import { useMediaMinLG } from 'f-web/src/hooks'

export default function SectionLabel({ children }) {
  const isMinLG = useMediaMinLG()

  return (
    <FView mb={isMinLG ? 50 : 25}>
      <FView w={isMinLG ? 200 : 115} h={3} bg={colors.primary} />
      <FText h4 h2={isMinLG} bold>
        {children}
      </FText>
    </FView>
  )
}
