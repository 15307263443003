import React from 'react'
import { FView } from 'components'
import { css } from 'emotion'

export default function FButton({ label, animateOnHover, onClick, disabled, ...props }) {
  return (
    <FView
      className={animateOnHover ? classes.noselectFloat : classes.noselect}
      cursor="pointer"
      minHeight={50}
      center
      onClick={disabled ? null : onClick}
      {...props}
    />
  )
}

const classes = {
  noselect: css({
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
  }),
  noselectFloat: css({
    ':hover': {
      transform: 'translateY(-4px)',
    },
    transition: 'all .5s cubic-bezier(.19,1,.4,1)',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
  }),
}
